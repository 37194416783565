// import utils
import { log, get, getAll } from "./utils.js";

// import npm packages
// import "netlify-identity-widget";

document.addEventListener("DOMContentLoaded", function () {
  const audio = document.getElementById("audio");
  const playButton = document.getElementById("playButton");
  const audioTimer = document.getElementById("audioTimer");
  const audioTimeline = document.querySelector(".audioTimeline");
  const audioTimelineProgress = document.querySelector(
    ".audioTimelineProgress"
  );
  const sources = document.querySelectorAll(".source");

  let currentTime, currentHours, currentMinutes, currentSeconds;

  // audio.src = "https://radioloss.com/assets/sounds/RadioLoss.ogg";
  audio.src = "https://radioloss.com/assets/sounds/RadioLoss.mp3";
  audio.load();

  audio.addEventListener("canplaythrough", () => {
    audioTimeline.addEventListener("click", (e) => {
      e.preventDefault();

      const timelineWidth = audioTimeline.offsetWidth;
      const clickPosition = e.offsetX;
      const ratio = clickPosition / timelineWidth;

      if (!isNaN(audio.duration) && audio.duration > 0) {
        audio.currentTime = ratio * audio.duration;
      } else {
        console.warn("Audio duration is not valid for seeking.");
      }
    });
  });

  audio.addEventListener("timeupdate", () => {
    currentTime = audio.currentTime;

    currentHours = Math.floor(currentTime / 3600);
    currentHours = currentHours < 10 ? "0" + currentHours : currentHours;

    currentMinutes = Math.floor((currentTime % 3600) / 60);
    currentMinutes =
      currentMinutes < 10 ? "0" + currentMinutes : currentMinutes;

    currentSeconds = Math.floor(currentTime % 60);
    currentSeconds =
      currentSeconds < 10 ? "0" + currentSeconds : currentSeconds;

    audioTimer.innerHTML = `${currentHours}:${currentMinutes}:${currentSeconds}`;

    const progressPercentage = (audio.currentTime / audio.duration) * 100;
    audioTimelineProgress.style.width = `${progressPercentage}%`;
  });

  playButton.addEventListener("click", () => {
    if (audio.paused) {
      audio.play();
      playButton.innerHTML = "Forget";
    } else {
      audio.pause();
      playButton.innerHTML = "Recall";
    }
  });

  audio.addEventListener("waiting", () => {
    document.body.style.cursor = "wait";
  });

  audio.addEventListener("playing", () => {
    document.body.style.cursor = "auto";
  });

  sources.forEach((source) => {
    let sourceTimeMark = source.getAttribute("data-time-mark"); //HH:MM:SS
    let sourceTimeMarkArray = sourceTimeMark.split(":");
    let sourceTimeMarkInSeconds =
      +sourceTimeMarkArray[0] * 60 * 60 +
      +sourceTimeMarkArray[1] * 60 +
      +sourceTimeMarkArray[2];

    source.addEventListener("click", () => {
      audio.currentTime = sourceTimeMarkInSeconds;
    });
  });
});

// decap cms
// const netlifyIdentity = () => {
//   if (window.netlifyIdentity) {
//     window.netlifyIdentity.on("init", (user) => {
//       if (!user) {
//         window.netlifyIdentity.on("login", () => {
//           document.location.href = "/admin/";
//         });
//       }
//     });
//   }
// };

// netlify identity widget
// netlifyIdentity();
